import styled from "styled-components"
import { LG, XL } from "../../../utils/constants"

import {
  MainH3,
  MainParagraph,
  SupTitle,
  MainTextLink,
} from "../../../utils/GlobalComponents"

export const Wrapper = styled.div`
  background-color: ${props => props.theme.neutralSilver};
  position:relative;
  margin-bottom:40px;
  

`

export const Grid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  /*   align-items: center; */
  padding: 170px 0;

  @media (max-width: ${XL}) {
    grid-template-columns: 1fr;
    padding: 170px 30px;
  }

  @media (max-width: ${LG}) {
    padding: 40px 30px;
  }
`
export const TitleContainer = styled.div`
  justify-self: end;
  padding-right: 10px;
`

export const TextContainer = styled.div`
  padding-left: 15px;
  margin-top: 30px;

  @media (max-width: ${XL}) {
    padding-left: 0;
  }
`

export const Title = styled(MainH3)`
  margin-top: 12px;
  max-width: 480px;

  @media (max-width: ${XL}) {
    max-width: unset;
    p {
      margin-bottom: 0;
    }
  }
`

export const Paragraph = styled(MainParagraph)`
  margin-bottom: 15px;
  max-width: 480px;

  @media (max-width: ${XL}) {
    max-width: unset;
  }
`

export const TopTitle = styled(SupTitle)``

export const TextLink = styled(MainTextLink)`
  margin-top: 15px;
`
